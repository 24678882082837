export const formatPrice = (price: string, currency: string = ''): string => {
	const numbers = price.match(/\d+/g)
	const symbol = currencyToSymbol(currency)
    if (numbers && numbers.length) {
        const number = Number(numbers[0])
        const place = thousands.getPlace(number)
        if (!place) return `${symbol}${number}`
        const formatted = (number / place.value).toFixed(1).replace(".0", "") + " " + place.unit
        return `${symbol}${formatted}`
    }
    return ''
}

export function currencyToSymbol (currency: string) {
    switch ((currency || "").toUpperCase()) {
        case "EUR": return "€"
        case "TRY": return "₺"
        case "GBP": return "£"
        default: return "$"
    }
}

class ThousandsValues {
	public places: ThousandsPlace[] = [];

	static isCreating: boolean = false;

	constructor () {
		this.places.push(new ThousandsPlace("B", 1000000000))
		this.places.push(new ThousandsPlace("M", 1000000))
		this.places.push(new ThousandsPlace("K", 1000))
	}

	getPlace (num: number) {
		return this.places.find(place => place.isInstance(num));
	}
}

class ThousandsPlace {
	
	constructor (public unit: string, public value: number) {}

	isInstance (value: number):boolean {
		return (value / this.value >= 1)
	}

}

const thousands = new ThousandsValues()