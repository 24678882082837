export type IEventAttributes = Record<string, string | number | boolean>
export type IEvent = {
  event: string
  action?: string
  label?: string
  amount?: number
  attributes?: IEventAttributes
}

type IFullEvent = IEvent & {
  uid: string
  createdTime: number
}

let drainTimer: NodeJS.Timeout
let drainWaiting: boolean
const queue = {
  url: 'https://tbd4rmdvjk.execute-api.us-east-1.amazonaws.com/dev/events/queue',
  // url: 'http://localhost:8009/events/queue',
  debug: false,//true,
  running: false,
  interval: 10000,
  max: 100,
  events: [] as IFullEvent[],
  uid: '',
}

function makeUid () {
  try {
    if (typeof window !== 'undefined' && typeof window.crypto === 'object' && 'randomUUID' in window.crypto) {
      return (window.crypto as any).randomUUID()
    }
  } catch {}
  
  return 'unkown'
}

function getUid () {
  if (queue.uid) return queue.uid

  queue.uid = localStorage.getItem('_sw_uid') || ''

  if (!queue.uid) {
    queue.uid = makeUid()
    localStorage.setItem('_sw_uid', queue.uid)
  }
  
  return queue.uid
}

export function pushEvent (event: IEvent) {
  const full = {
    ...event,
    uid: getUid(),
    createdTime: Date.now()
  }

  queue.events.push(full)

  if (queue.debug) {
    console.log('Pushed event', full)
  }

  runQueue()
  initDrainEventsOnce({})
}

const pushEventOnce = (base: IEvent) => once<Partial<IEvent>, void>((event) => pushEvent({ ...base, ...event }))

const initDrainEventsOnce = once(() => {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === "hidden") {
      drainQueue()
    }
  })
  document.addEventListener('beforeunload', () => {
    drainQueue()
  })
})

export const pushFirstAdViewed = pushEventOnce({ event: 'firstAdViewed' })

function runQueue () {
  if (queue.events.length >= queue.max) {
    drainQueue()
  } else if (!drainWaiting) {
    drainWaiting = true
    drainTimer = setTimeout(() => {
      drainWaiting = false
      drainQueue()
    }, queue.interval)
  }
}

function drainQueue () {
  clearTimeout(drainTimer)
  const events = queue.events.splice(0, queue.max)

  if (events.length) {
    if (queue.debug) {
      console.log("SEND TO ANALYTICS", events)
    } else if (typeof window.navigator.sendBeacon === 'function') {
      window.navigator.sendBeacon(queue.url, JSON.stringify(events))
    } else {
      return fetch(queue.url, {
        method: 'POST',
        body: JSON.stringify(events),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .catch(() => {
        queue.events.push(...events)
      })
    }
  }
}

function once<V = unknown, O = unknown>(fn: (v: V) => O) {
  let called = false
  
  return (v: V) => {
    if (!called) {
      called = true
      return fn(v)
    }
  }
}

function throttle (fn: () => void, n = 0) {

}