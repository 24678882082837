type IProfile = {
  getProfileId: () => string;
  getPanoramaId: () => string;
};

type ILotame = {
  config: { onProfileReady: (profile: IProfile) => void };
  cmd: any[];
  data: {};
  sync: (data: any) => any;
};

const lotameClientId = "15969";

export const lotame = {
  initialize: (onProfileReady: (profile: IProfile) => any): ILotame => {
    const client: ILotame = (window as any)[`lotame_sync_${lotameClientId}`] = {
      config: {
        onProfileReady,
      },
      sync: () => {
        console.warn('Lotame client not ready - pass a callback function into client.cmd.push(() => client.sync(data))')
      },
      data: {},
      cmd: [],
    } as ILotame;

    try {
      const script = document.createElement("script");
      script.async = true;
      script.src = "//tags.crwdcntrl.net/lt/c/"+lotameClientId+"/sync.min.js";
      script.onerror= function (e) {
        console.warn('Lotame client not ready', e)
      }
      document.body.appendChild(script);
    } catch (e) {
      console.log(e);
    }

    return client;
  },
};
